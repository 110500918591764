import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import levelsPreviewData from '../../../../data/previews/levels.yml';
import pageHeroData from '../../../../data/pages/components.yml';
import styles from './styles/level.module.scss';

const Level = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Level;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Level"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Themes" tierThree="Level" />

      <PageNavigation links={['Type', 'Usage', 'Platform']} />

      <Section title="Type">
        <SectionSubhead>Base</SectionSubhead>
        <Paragraph>Use level base colors to segment a layout.</Paragraph>

        <ComponentPreview
          name="ThemesBases"
          layout="default"
          previewData={levelsPreviewData.bases}>
          <Level className={styles.basePreviewWrapper}>
            <div className={styles.basePreview}>Base</div>
          </Level>
        </ComponentPreview>

        <SectionSubhead>Accent</SectionSubhead>
        <Paragraph>
          Use the accent color <strong>only</strong> on its respective base
          color.
        </Paragraph>
        <ComponentPreview
          name="ThemesAccents"
          layout="default"
          previewData={levelsPreviewData.accents}>
          <Level type="base" className={styles.accentsBasePreviewWrapper}>
            <div className={styles.accentsBasePreview}>Base</div>
          </Level>
          <Level type="accent" className={styles.accentsAccentPreviewWrapper}>
            <div className={styles.accentsAccentPreview}>Accent</div>
          </Level>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <SectionSubhead>How to Use</SectionSubhead>
        <Paragraph>
          Start with Level 0 and build up one level at a time.
        </Paragraph>
        <img
          src={require('../../../../imgs/diagrams/levels-build.gif')}
          className="uni-margin--two--bottom"
        />

        <SectionSubhead>Primary Region</SectionSubhead>
        <Paragraph>
          The primary region of the screen should be Level 0, even if that
          region is in the middle of the screen.
        </Paragraph>
        <DontDo
          dontText="use higher levels for primary content regions."
          doText="use Level 0 for primary content regions."
          imgFilename="themes-levels"
        />
        <SectionSubhead>Space and Division</SectionSubhead>
        <Paragraph>
          When building an interface, start first with space to reveal
          relationships between content before considering a level change. In
          more severe instances, more contrast is requried to create visual
          division between different areas. Follow our{' '}
          <Link href="/guidelines/layout/space/design">space</Link> and{' '}
          <Link href="/guidelines/layout/division/design">
            division guidelines
          </Link>{' '}
          for more information.
        </Paragraph>

        <SectionSubhead>Levels and Layering</SectionSubhead>
        <Paragraph>
          Levels are background colors for two-dimensional regions on the same
          plane as their peers — they do not cast shadows by default. See our{' '}
          <Link href="/guidelines/layering/design">layering guidelines</Link>{' '}
          for more on shadows.
        </Paragraph>
        <DontDo
          dontText="add shadows to regions unless the element is layered."
          doText="keep levels on the same elevation."
          imgFilename="themes-layers"
        />

        <SectionSubhead>Base and Accents</SectionSubhead>
        <Paragraph>
          Each level has one accent color. An accent color should only be used
          with its respective base color.
        </Paragraph>
        <DontDo
          dontText="pick accents from any level."
          doText="use the accent matching the region’s base color."
          imgFilename="themes-matchingaccents"
        />

        <SectionSubhead>Visual Enhancements</SectionSubhead>
        <Paragraph>
          Accents should only be used for subtle graphics like fine lines or
          small regions.
        </Paragraph>
        <DontDo
          dontText="use accents as the background for a large region."
          doText="use accent colors for visual enhancements."
          imgFilename="themes-accents"
        />
      </Section>

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
